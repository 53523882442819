.touchable {
	transition:
		opacity 0.2s ease,
		filter 0.3s ease;
}
.touchable-enabled {
	pointer-events: auto;
	cursor: pointer;
}
.touchable-disabled {
	pointer-events: none;
	opacity: 0.5;
}
.touchable-disable-no-style {
	pointer-events: none;
}
.touchable-animated:hover {
	filter: brightness(1.3);
}
@media (max-width: 768px) {
	.touchable-animated:active {
		opacity: 0.5;
	}
}
